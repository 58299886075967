import {Link} from "react-router-dom";
import Logo from "../images/logo.svg";
import {FaBars} from "react-icons/fa";
import {AiOutlineClose} from "react-icons/ai";
import {useEffect, useState} from "react";

export function Header() {

    const [isNavShowing, setNavShowing] = useState(window.innerWidth > 600)

    const closeNavHandler = () => {
        setNavShowing(window.innerWidth > 600);
    }

    useEffect(() => {

        window.addEventListener("resize", closeNavHandler);

        return () => {
            window.removeEventListener("resize", closeNavHandler);
        }
    }, []);

    return (<>
        <nav>
            <div className={"container navContainer"}>
                <Link to={"/"} className={"navLogo"}>
                    <img src={Logo} alt={""}/>
                </Link>
                {isNavShowing && <ul className={"navMenu"}>
                    <li><Link to={"/"} onClick={closeNavHandler}>Home</Link></li>
                    <li><Link to={"/about"} onClick={closeNavHandler}>Über uns</Link></li>
                    <li><Link to={"/contact"} onClick={closeNavHandler}>Kontakt</Link></li>
                </ul>}
                <button className={"navToggleBtn"} onClick={() => {
                    setNavShowing(!isNavShowing)
                }}>
                    {isNavShowing ? <AiOutlineClose/> : <FaBars/>}
                </button>
            </div>
        </nav>
    </>)
}
