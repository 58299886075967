import {SlideShow} from "../components/SlideShow";
import {FeaturedCard} from "../components/FeaturedCard";
import {Card} from "../components/Card";
import {FaChevronCircleRight} from "react-icons/fa";
import {Link} from "react-router-dom";
import useSWR from "swr";
import {NavBackground} from "../components/NavBackground";
import {apiUrl} from "../index";

export function Home() {


    const fetcher = async (path) => {
        return fetch(apiUrl + path).then((res) => res.json());
    }

    const {data, error, isLoading} = useSWR("/houses/list", fetcher);


    if (isLoading) {
        return (
            <>
                <NavBackground backgroundColor={"var(--color-primary)"}></NavBackground>
                <div className={"loadingStatus"}>
                    <h2>Loading...</h2>
                </div>
            </>
        )
    }

    if (error) {
        return (
            <>
                <NavBackground backgroundColor={"var(--color-primary)"}/>
                <div className={"loadingStatus"}>
                    <h2>Error</h2>
                </div>
            </>
        );
    }

    const images = [];

    data.forEach((item) => {
        if (images.length < 5) {
            images.push(apiUrl + "/images/" + item.thumbnail);
        }
    });

    return (
        <>

            <NavBackground backgroundColor={"var(--color-primary)"} position={"absolute"}/>

            {data.length > 0 && <SlideShow images={images}/>}

            <div className={"offerContainer"}>

                {data.length > 0 ? <>

                    <div className={"offersTitle"}>
                        {window.innerWidth < 800 ? "Ihre exklusive Immobilienauswahl auf Sylt" : "Wohnträume in besten Lagen: Ihre exklusive Immobilienauswahl auf Sylt"}
                    </div>

                    <div className={"featuredOffers"}>
                        {data.filter((house) => house.featured).map(house => (
                            <FeaturedCard key={house._id} properties={house}/>
                        ))}
                    </div>

                    <div className={"normalOffers"}>
                        {data.filter((house) => !house.featured).map(house => (
                            <Card key={house._id} properties={house}/>
                        ))}
                    </div>
                </> : <div className={"emptyOffers"}>Aktuell sind keine Angebote vorhanden</div>}


            </div>

            <div className={"aboutSection"}>
                <div className={"aboutTitle"}>
                    Über uns
                </div>
                <div className={"aboutText"}>
                    INSOLITO steht für Qualität: Qualität, die man sieht und fühlt. In jedem Raum und jeder Ecke unserer
                    Appartements. Neben den renommierten Marken finden Sie in unseren Appartements viele Dinge, die es
                    NUR bei INSOLITO gibt. Denn viele Produkte lässt INSOLITO exklusiv in traditionellen Manufakturen
                    fertigen.
                </div>

                <div className={"aboutLearnMoreContainer"}>
                    <div className={"aboutLearnMoreBtn"}>
                        <Link to={"/about"}> <FaChevronCircleRight style={{marginRight: "0.4vw"}} size={"2.5vw"}/>
                            Mehr erfahren </Link>
                    </div>
                </div>

            </div>

        </>
    )
}
