import {IoLocationSharp} from "react-icons/io5";
import {CardProperties} from "./CardProperties";
import {useNavigate} from "react-router-dom";
import {apiUrl} from "../index";


export function FeaturedCard(props) {

    const properties = props.properties;

    const navigate = useNavigate();

    return (
        <>
            <div className="featuredCard" onClick={() => navigate("/house/" + properties._id)}>
                <div className="featuredCardContainer">
                    <div className="featuredCardImage" style={{
                        backgroundImage: `url(${apiUrl + "/images/" + properties.thumbnail})`
                    }}/>
                    <div className="featuredCardInfo">
                        <div className="featuredCardPrice"><span>{new Intl.NumberFormat('de-DE').format(
                            properties.price,
                        )}</span> €
                        </div>
                        <div className={"featuredCardTitle"}>{properties.title}</div>
                        <div className="featuredCardLocation">
                            <IoLocationSharp className={"locationIcon"}/>{properties.location}</div>
                        <CardProperties plotSm={properties.plotSm} houseSm={properties.houseSm}
                                        bathrooms={properties.bathrooms} bedrooms={properties.bedrooms}/>
                    </div>
                </div>
            </div>
        </>
    )
}
