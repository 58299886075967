import {useParams} from "react-router-dom";
import useSWR from "swr";
import {NavBackground} from "../components/NavBackground";
import {apiUrl} from "../index";
import {SlideShow} from "../components/SlideShow";
import {CardProperties} from "../components/CardProperties";
import {ContactForm} from "../components/ContactForm";

export function HouseDetails(props) {

    const params = useParams();

    const fetcher = async (path) => {
        return fetch(apiUrl + path).then((res) => res.json());
    }

    const {data, error, isLoading} = useSWR("/houses/get/" + params.id, fetcher);


    if (isLoading) {
        return (
            <>
                <NavBackground backgroundColor={"var(--color-primary)"}></NavBackground>
                <div className={"loadingStatus"}>
                    <h2>Loading...</h2>
                </div>
            </>
        )
    }

    if (error) {
        return (
            <>
                <NavBackground backgroundColor={"var(--color-primary)"}/>
                <div className={"loadingStatus"}>
                    <h2>Error</h2>
                </div>
            </>
        );
    }

    const images = [];

    data.images.forEach((image) => {
        images.push(apiUrl + "/images/" + image);
    });


    return (
        <>
            <NavBackground backgroundColor={"var(--color-primary)"} position={"absolute"}/>

            <SlideShow images={images}/>

            <div className={"houseDetails"}>
                <div className={"detailContainer"}>
                    <div className={"detailHeader"}>
                        <div className={"detailTitle"}>{data.title}</div>
                        <CardProperties plotSm={data.plotSm} houseSm={data.houseSm}
                                        bathrooms={data.bathrooms} bedrooms={data.bedrooms}/>
                    </div>
                    <div className={"detailTextContainer"}>
                        <div className={"detailText"} dangerouslySetInnerHTML={{__html: data.description}}/>

                    </div>
                    <ContactForm id={params.id}/>
                </div>
            </div>

        </>
    )
}
