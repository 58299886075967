import {ContactForm} from "../components/ContactForm";
import {NavBackground} from "../components/NavBackground";
import {Link} from "react-router-dom";

export function Contact() {
    return (
        <>
            <NavBackground backgroundColor={"var(--color-primary)"}/>

            <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                <div className={"contactTitle"}>
                    Kontaktieren Sie uns!
                </div>
            </div>

            <div className={"contactInfo"}>
                <ul>
                    <li><b><Link
                        to={"mailto:info@insolito-immobilien.de"}>E-Mail: <u>info@insolito-immobilien.de</u></Link></b>
                    </li>
                    <li><b><Link to={"tel:+4915754618922"}>Tel.: <u>+49 157 54618922</u></Link></b></li>
                </ul>
            </div>


            <ContactForm id={"contact_page"}/>
        </>
    )
}
