import {CardProperties} from "./CardProperties";
import {IoLocationSharp} from "react-icons/io5";
import {useNavigate} from "react-router-dom";
import {apiUrl} from "../index";

export function Card(props) {

    const properties = props.properties;

    const navigate = useNavigate();

    return (
        <>
            <div className={"card"} onClick={() => navigate("/house/" + properties._id)}>
                <div className="cardContainer">
                    <div className="cardImage" style={{
                        backgroundImage: `url(${apiUrl + "/images/" + properties.thumbnail})`
                    }}/>

                    <div className={"cardInfo"}>
                        <div className={"cardPrice"}><span>{new Intl.NumberFormat('de-DE').format(
                            properties.price,
                        )}</span> €
                        </div>
                        <div className={"cardLocation"}>
                            <IoLocationSharp className={"locationIcon"}/> {properties.location}
                        </div>
                        <CardProperties plotSm={properties.plotSm} houseSm={properties.houseSm}
                                        bathrooms={properties.bathrooms} bedrooms={properties.bedrooms}/>
                    </div>
                </div>
            </div>
        </>
    )
}
