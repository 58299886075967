import {Slide} from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import {FaChevronLeft, FaChevronRight} from "react-icons/fa6";
import {useNavigate} from "react-router-dom";

export function SlideShow(props) {

    const images = props.images;

    const indicators = () => (<div className="indicator"/>);

    const buttonStyle = {
        width: "30px",
        background: 'none',
        border: '0px',
        margin: '1.3vw'
    };

    const properties = {
        prevArrow: <button style={{...buttonStyle}}><FaChevronLeft className={"chevron"}/></button>,
        nextArrow: <button style={{...buttonStyle}}><FaChevronRight className={"chevron"}/></button>,
        transitionDuration: 500,
    }

    return (
        <>
            <Slide indicators={indicators} {...properties}>
                {images.map((image, i) => (
                    <div key={i} className="each-slide-effect">
                        <div style={{'backgroundImage': `url(${image})`}}>
                        </div>
                    </div>
                ))}
            </Slide>
        </>
    )
}
