import {NavBackground} from "../components/NavBackground";

export function About() {
    return (
        <>
            <NavBackground backgroundColor={"var(--color-primary)"}/>
            <h1>Über uns text hier einfuegen</h1>
        </>
    )
}
