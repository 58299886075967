import Logo from "../images/logo.svg";
import {Link} from "react-router-dom";

export function Footer() {
    return (
        <>
            <footer>
                <div className={"footerContainer"}>
                    <div className={"footerContent"}>
                        <div className={"footerLeft"}>
                            <ul>
                                <li><b><Link to={"/"}>Home</Link></b></li>
                                <li><b><Link to={"/about"}>Über uns</Link></b></li>
                                <li><b><Link to={"/contact"}>Kontakt</Link></b></li>
                            </ul>
                            <ul>
                                <li><Link to={"/imprint"}>Impressum</Link></li>
                                <li><Link to={"/privacy"}>Datenschutz</Link></li>
                            </ul>
                        </div>
                        <Link to={"/"}>
                            <div className={"footerLogoContainer"}>
                                <img className={"footerLogo"} src={Logo} alt={""}/>
                            </div>
                        </Link>
                        <div className={"footerRight"}>
                            <ul>
                                <li><b><Link to={"mailto:info@insolito-immobilien.de"}>info@insolito-immobilien.de</Link></b></li>
                                <li><b><Link to={"tel:+4915754618922"}>+49 157 54618922</Link></b></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}
