import {NavBackground} from "../components/NavBackground";

export function Imprint() {
    return (
        <>
            <NavBackground backgroundColor={"var(--color-primary)"}/>
            <div className={"imprintText"}>
                <h1>Impressum</h1>
                <p>Insolito Sylt GmbH<br/>
                    Norderweg 5<br/>
                    25996 Wenningstedt / Sylt</p>
                <p>Handelsregister: HRB 14343 FL<br/>
                    Registergericht: Amtsgericht Hamburg</p>
                <p><strong>Vertreten durch:</strong><br/>
                    Philipp Rolle<br/>
                    Markus Rolf Zielke</p>
                <h2>Kontakt</h2>
                <p>Telefon: +49 157 54618922<br/>
                    E-Mail: info@insolito-immobilien.de</p>
                <h2>Umsatzsteuer-ID</h2>
                <p>Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:<br/>
                    DE314223899</p>
                <h2>Redaktionell verantwortlich</h2>
                <p>Insolito Sylt GmbH<br/>
                    Norderweg 5<br/>
                    25996 Wenningstedt / Sylt</p>
                <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
                <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
                    Verbraucherschlichtungsstelle
                    teilzunehmen.</p>
                <p>Quelle: <a href="https://www.e-recht24.de">e-recht24.de</a></p>

                <p><br/> <strong>Verantwortlich für den Inhalt gem. § 55 II RStV</strong><br/> INSOLITO GmbH<br/> <br/>
                    <br/><br/>
                    <h2>Rechtliche Hinweise</h2><br/> <br/> <strong><em>Urheberrechte</em></strong><br/> Alle
                    Inhalte auf dieser Webseite sind urheberrechtlich geschützt. Texte, Bilder und Grafiken unterliegen
                    dem Schutz des Urheberrechts und anderer Schutzgesetze. Der Inhalt darf nicht zu kommerziellen
                    Zwecken kopiert, verbreitet, verändert oder Dritten zugänglich gemacht werden. Jede Nutzung,
                    insbesondere die Speicherung in Datenbanken, Vervielfältigung, Verbreitung und jede Form von
                    gewerblicher Nutzung sowie die Weitergabe an Dritte - auch in Teilen oder in überarbeiteter Form -
                    ohne Zustimmung des Betreibers bzw. des Urhebers ist untersagt.<br/> <br/>
                    <strong><em>Haftungsausschluss</em></strong><br/> Unsere Website und die darauf bereitgestellten
                    Informationen werden einer sorgfältigen Überprüfung unterzogen und regelmäßig aktualisiert.
                    Diesbezüglich kann jedoch keine Garantie über Vollständigkeit, Richtigkeit oder Aktualität der
                    Angaben gewährleistet werden. Dies beinhaltet besonders direkte oder indirekte Links zu anderen
                    Websites. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber
                    verantwortlich.<br/> <br/> <strong><em>Linkhaftungsausschluss</em></strong><br/> Das Landgericht
                    Hamburg hat im Urteil 312 O 85/98 „Haftung für Links“ entschieden, dass durch die Verankerung eines
                    Links auf einer Webseite/Homepage die Inhalte der verlinkten Seiten mit zu verantworten sind. Wir
                    distanzieren uns ausdrücklich von allen Inhalten, die sich hinter den angegebenen Links, den
                    dahinter stehenden Servern, weiterführenden Links, Gästebüchern und sämtlichen anderen sichtbaren
                    und unsichtbaren Inhalten verbergen. Sollte eine der Seiten auf den entsprechenden Servern gegen
                    geltendes Recht verstoßen, so ist uns dieses nicht bekannt. Auf entsprechende Benachrichtigung hin
                    wird selbstverständlich der Link zu dem entsprechenden Server entfernt.<br/> <br/>
                    <br/><br/>
                </p>

            </div>
        </>
    )
}
