import {TbMeterSquare} from "react-icons/tb";
import {MdBed, MdOutlineHouse} from "react-icons/md";
import {BiBath} from "react-icons/bi";

export function CardProperties(props) {
    return (
        <>
            <hr className={"cardPropertiesLine"}/>
            <div className="cardProperties">
                <div className={"cardProperty"}>
                    <TbMeterSquare className={"propertyIcon"}/> {props.plotSm}
                </div>
                <div className={"cardProperty"}>
                    <MdOutlineHouse className={"propertyIcon"}/> {props.houseSm}
                </div>
                <div className={"cardProperty"}>
                    <MdBed className={"propertyIcon"}/> {props.bedrooms}
                </div>
                <div className={"cardProperty"}>
                    <BiBath className={"propertyIcon"}/> {props.bathrooms}
                </div>
            </div>
        </>
    )
}
