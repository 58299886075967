export function NavBackground(props) {

    const color = props.backgroundColor ? props.backgroundColor : 'white';
    const position = props.position ? props.position : "static";

    return (
        <>
            <div className={"navBackground"} style={{backgroundColor: color, position: position, zIndex: -10}}/>
        </>
    )
}
