import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {Layout} from "./components/Layout";
import {ErrorPage} from "./pages/ErrorPage";
import {Home} from "./pages/Home";
import {HouseDetails} from "./pages/HouseDetails";
import {About} from "./pages/About";
import {Imprint} from "./pages/Imprint";
import {Privacy} from "./pages/Privacy";
import {Contact} from "./pages/Contact";


const development = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export default function isDev() {
    return development;
}


export const apiUrl = isDev() ? window.location.protocol + "//" + window.location.hostname + ":4000" : window.location.protocol + "//api.insolito-immobilien.de";


const router = createBrowserRouter([
    {
        path: "/",
        element: <Layout/>,
        errorElement: <ErrorPage/>,
        children: [
            {index: true, element: <Home/>},
            {path: "house/:id", element: <HouseDetails/>},
            {path: "about", element: <About/>},
            {path: "imprint", element: <Imprint/>},
            {path: "privacy", element: <Privacy/>},
            {path: "contact", element: <Contact/>},
        ]
    }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <RouterProvider router={router}/>
    </React.StrictMode>
);

