import {Header} from "./Header";
import {Footer} from "./Footer";
import {Outlet} from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from "react-toastify";
import ScrollToTop from "./ScrollToTop";

export function Layout() {
    return (
        <>
            <Header/>
            <ScrollToTop/>
            <Outlet/>
            <ToastContainer/>
            <Footer/>
        </>
    )
}
