import {Link} from "react-router-dom";

export function ErrorPage() {
    return (
        <>
            <div className={"errorContainer"}>
                <span className={"errorTitle"}>Error 404</span>
                <div className={"errorText"}>
                    Seite nicht gefunden
                </div>
                <div className={"errorHomeBtn"}><Link to={"/"}>Home</Link></div>
            </div>
        </>
    )
}
