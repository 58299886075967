import {apiUrl} from "../index";
import {toast} from "react-toastify";

export function ContactForm(props) {

    const id = props.id;

    function onSubmit(e) {
        e.preventDefault();


        const formData = new FormData(e.target);

        const body = Object.fromEntries(formData);

        window.fetch(apiUrl + "/contact/send", {
            headers: {
               "content-type": "application/json",
            },
            body: JSON.stringify({
                id: id,
                ...body
            }),
            method: "post"
        } ).then(async (response) => {
            const json = await response.json();
            if(json.message === "success") {
                toast.success("Abgesendet!", {position: "bottom-right", autoClose: 5000});
                e.target?.reset();
            }else {
                toast.error(json.error, {position: "bottom-right", autoClose: 8000});
            }
        });

    }


    return (
        <>

            <section className={"contact"}>
                <form className={"contactForm"} onSubmit={onSubmit}>
                    <h2>Kontakt</h2>
                    <div className={"inputBox"}>
                        <label>Ihr Name</label>
                        <input type={"text"} className={"contactField"} placeholder={"Name"} name={"name"}
                               maxLength={40} required/>
                    </div>
                    <div className={"inputBox"}>
                        <label>Email Adresse</label>
                        <input type={"email"} className={"contactField"} placeholder={"Email"} name={"email"}
                               maxLength={60} required/>
                    </div>
                    <div className={"inputBox"}>
                        <label>Telefonnummer</label>
                        <input type={"tel"} className={"contactField"} placeholder={"Tel."} name={"phone"}
                               maxLength={30} required/>
                    </div>
                    <div className={"inputBox"}>
                        <label>Straße, Hausnummer</label>
                        <input className={"contactField"} placeholder={"Str., Nr."} name={"street"}
                               maxLength={60} required/>
                    </div>
                    <div className={"inputBox"}>
                        <label>Postleitzahl, Stadt</label>
                        <input className={"contactField"} placeholder={"PLZ, Stadt"} name={"zipCode"}
                               maxLength={40} required/>
                    </div>
                    <div className={"inputBox"}>
                        <label>Ihre Nachricht</label>
                        <textarea className={"contactField messageField"} placeholder={"Nachricht"} name={"message"}
                                  maxLength={10000} required/>
                    </div>
                    <div className={"inputBox"} style={{position: "absolute", visibility: "hidden"}}>
                        <textarea className={"contactField messageField"} placeholder={"abc"} name={"abc"}
                                  maxLength={10}/>
                    </div>
                    <button className={"contactSubmitBtn"} type={"submit"}>Absenden</button>
                </form>
            </section>

        </>
    )
}
